import { currencyConfigDict } from '@/config/currency';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { differenceInMilliseconds, differenceInSeconds } from 'date-fns';
import { Nullable } from '../../model/common';
import { FetchingStatus } from '../../model/fetchingStatus';
import { getUserMarkets } from '../../services/UserService';
import { RootState } from '../store';

export const fetchUserMarkets = createAsyncThunk('marketPair/fetch', async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const markets = await getUserMarkets(state.auth.user);
    const pairs = markets.map((item) => generatePair(item.securityCode));
    dispatch(setMarketPairs(pairs));
    return markets;
});

export const generatePair = (celerPair: string) => {
    if (celerPair.indexOf('/') == -1) throw new Error(`Cannot parse celer pair ${celerPair} with no / division`);
    const [celerBase, celerQuote] = celerPair.split('/');
    const baseMapping = currencyConfigDict[celerBase];
    const quoteMapping = currencyConfigDict[celerQuote];

    const getBase = (key: string) => (baseMapping ? baseMapping[key] : celerBase);
    const getQuote = (key: string) => (quoteMapping ? quoteMapping[key] : celerQuote);

    return {
        celer: celerPair,
        netdania: `${getBase('netdania')}/${getQuote('netdania')}`,
        show: `${getBase('show')}/${getQuote('show')}`
    } as PairMap;
};

export interface PairMap {
    celer: string;
    netdania: string;
    show: string;
}

export interface SubscriptionInfo {
    type: 'tob' | 'full';
    pair: string;
    exchange: string;
    settlementType: string;
}

export interface MarketPairState {
    activePair: Nullable<PairMap>;
    pairs: PairMap[];
    favouritePairs: PairMap[];
    status: FetchingStatus;
    bidAsk: Record<string, { bid: number; ask: number; timestamp: number; latency: string }>;
    latestBidAsk: { celerPair: string; bid: number; ask: number; timestamp: number };
    subscriptions: Record<string, SubscriptionInfo>;
}

const initialState: MarketPairState = {
    activePair: {
        celer: null,
        netdania: null,
        show: null
    },
    pairs: [],
    favouritePairs: [],
    status: 'idle',
    bidAsk: {},
    latestBidAsk: { celerPair: '', bid: 0, ask: 0, timestamp: 0 },
    subscriptions: {}
};

export const marketPairSlice = createSlice({
    name: 'marketPair',
    initialState,
    reducers: {
        addFavouritePair: (state, action: PayloadAction<PairMap>) => {
            const temp = [...state.favouritePairs];
            temp.push(action.payload);
            state.favouritePairs = temp;
        },
        removeFavouritePair: (state, action: PayloadAction<PairMap>) => {
            const temp = [...state.favouritePairs.filter((pair) => pair.celer !== action.payload.celer)];
            state.favouritePairs = temp;
        },
        setActivePair: (state, action: PayloadAction<PairMap>) => {
            state.activePair = action.payload;
            // console.log({ 'Setting active ': action.payload });
        },
        setMarketPairs: (state, action: PayloadAction<PairMap[]>) => {
            state.pairs = action.payload;
        },
        addBidAsk: (
            state,
            action: PayloadAction<{ celerPair: string; bid: number; ask: number; timestamp: number }>
        ) => {
            const bidAskInfo = action.payload;
            let latency;

            if (bidAskInfo.timestamp > 0) {
                const timestampVal = new Date(bidAskInfo.timestamp);
                const now = new Date();

                const secs = differenceInSeconds(now, timestampVal);
                const millis = differenceInMilliseconds(now, timestampVal);
                if (now > timestampVal) {
                    if (secs > 0) latency = `+${secs}.${millis % 1000}s`;
                    else latency = `+0.${millis}s`;
                } else latency = '+0.000s';
            }

            state.bidAsk[bidAskInfo.celerPair] = {
                bid: bidAskInfo.bid,
                ask: bidAskInfo.ask,
                timestamp: bidAskInfo.timestamp,
                latency
            };
            state.latestBidAsk = bidAskInfo;
        },
        clearBidAsk: (state, action: PayloadAction<{ celerPair: string }>) => {
            delete state.bidAsk[action.payload.celerPair];
        },
        appendSubscriptions: (state, action: PayloadAction<Record<string, SubscriptionInfo>>) => {
            state.subscriptions = { ...state.subscriptions, ...action.payload };
        },
        updateMarket: (state, action: PayloadAction<{ subscriptionId: string; subInfo: SubscriptionInfo }>) => {
            const { subscriptionId, subInfo } = action.payload;
            state.subscriptions[subscriptionId] = subInfo;
        },
        removeMarket: (state, action: PayloadAction<{ subscriptionId: string }>) => {
            const { subscriptionId } = action.payload;
            delete state.subscriptions[subscriptionId];
        },
        clearSubscriptions: (state) => {
            state.subscriptions = {};
            state.bidAsk = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserMarkets.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserMarkets.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(fetchUserMarkets.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

export const {
    setActivePair,
    addBidAsk,
    clearBidAsk,
    addFavouritePair,
    setMarketPairs,
    removeFavouritePair,
    appendSubscriptions,
    updateMarket,
    removeMarket,
    clearSubscriptions
} = marketPairSlice.actions;
export const selectMarketPairs = (state: RootState) => state.marketPair.pairs;
export const selectMarketPairsSubscription = (state: RootState) => state.marketPair.subscriptions;
export const selectFavouriteMarketPairs = (state: RootState) => state.marketPair.favouritePairs;
export const selectActiveMarketPair = (state: RootState) => state.marketPair.activePair;
export const selectBidAsk = (state: RootState) => state.marketPair.bidAsk;
export const selectLatestBidAsk = (state: RootState) => state.marketPair.latestBidAsk;
export const selectActiveBidAsk = (state: RootState) =>
    state.marketPair.bidAsk[state.marketPair.activePair?.celer || ''];

export default marketPairSlice.reducer;
