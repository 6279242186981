import ContextMenu, { ContextMenuItem } from '@/components/common/ContextMenu';
import Divider from '@/components/common/Divider';
import LiteOrderModal from '@/components/modal/LiteOrderModal';
import { qtyFormatterConfig } from '@/config/config';
import { currencyConfigDict } from '@/config/currency';
import { useAppSelector } from '@/state/hooks';
import { selectCurrentAccount } from '@/state/reducers/authSlice';
import { CollapsedBalances, selectBalanceStatus, selectNopBalance } from '@/state/reducers/balanceSlice';
import { isSameUTCDay } from '@/utils/format';
import useContextMenu from '@/utils/hooks/useContextMenu';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import cn from 'classnames';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { MdAddCircle, MdAutoAwesomeMotion, MdRemoveCircle } from 'react-icons/md';
import { useSelector } from 'react-redux';
import LiteActions from '../LiteActions';
import LiteBalance from './LiteBalance';

interface LiteBalancesProps {}

export default function LiteBalances(props: LiteBalancesProps) {
    const status = useSelector(selectBalanceStatus);
    const nopBalance = useSelector(selectNopBalance);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const formatQty = useNumberFormatter(qtyFormatterConfig);

    const [opened, handlers] = useDisclosure(false);

    const [selectedBalance, setSelectedBalance] = useState<CollapsedBalances>();
    const [showBalanceSettlements, setShowBalanceSettlements] = useState<Record<string, boolean>>({});
    const [initFrom, setInitFrom] = useState<string>('');
    const [initTo, setInitTo] = useState<string>('');

    const { contextMenuX, contextMenuY, contextMenuVisible, handleOpenContextMenu, handleCloseContextMenu } =
        useContextMenu();

    const onSelectingBalance = useCallback(
        (e: any, balance: CollapsedBalances) => {
            setSelectedBalance(balance);
            handleOpenContextMenu(e);
        },
        [handleOpenContextMenu]
    );

    const showSettlements = useMemo(() => {
        if (selectedBalance?.rows) {
            const hasSettlements = selectedBalance.rows.length > 0;
            const allSettlementsAreToday = selectedBalance.rows.every((row) => isSameUTCDay(row.valueDate));
            return hasSettlements && !allSettlementsAreToday;
        } else {
            return false;
        }
    }, [selectedBalance?.rows]);

    const balances = useMemo(() => nopBalance?.balances || [], [nopBalance]);
    const isSameSettlementDate = useMemo(() => {
        const flattenDates = balances.flatMap((balance) => balance.rows).map((row) => row.valueDate);
        const set = Array.from(new Set(flattenDates));
        return set.length === 1;
    }, [balances]);

    const nopBalanceAccountMatched = useMemo(() => {
        return currentAccount === nopBalance?.account;
    }, [currentAccount, nopBalance]);

    const selectedBalanceCurrency = useMemo(() => selectedBalance?.currency || '', [selectedBalance]);

    const cryptoBalances = useMemo(() => {
        return nopBalance?.balances.filter((balance) => currencyConfigDict[balance.currency]?.type === 'Crypto');
    }, [nopBalance]);

    const fiatBalances = useMemo(() => {
        return nopBalance?.balances.filter((balance) => currencyConfigDict[balance.currency]?.type === 'Fiat');
    }, [nopBalance]);

    const balanceTotals = useMemo(() => {
        return nopBalance?.balances.reduce((acc, balance) => acc + balance.totalAmountInBaseCurrency, 0) || 0;
    }, [nopBalance]);

    return (
        <Fragment>
            <div className="flex flex-col h-fit w-full gap-1 bg-brand-background-dark p-6">
                <span className="text-neutral-200 font-bold text-base sm:text-lg">Your Balances</span>
                <LiteActions orderModal={handlers} setInitFrom={setInitFrom} setInitTo={setInitTo} />
                <div className="flex flex-col gap-5">
                    {status === 'OPEN' && nopBalanceAccountMatched && (
                        <Fragment>
                            {cryptoBalances.length > 0 && (
                                <div className="flex flex-col items-center gap-3">
                                    <div className="flex w-full justify-between">
                                        <span className="text-sm sm:text-base">Crypto Assets</span>
                                    </div>
                                    <div className="flex w-full text-xs sm:text-sm text-neutral-400 gap-3 px-3">
                                        <span className="flex-[1_1_0]">Asset</span>
                                        <span className="flex-[1_1_0] text-end">Balance</span>
                                        {nopBalance && (
                                            <div className="text-right flex-[1_1_0]">
                                                Balance {nopBalance.baseCurrency ? `(${nopBalance.baseCurrency})` : ''}
                                            </div>
                                        )}
                                    </div>
                                    <Divider className="bg-neutral-700" />
                                    {cryptoBalances.map((balance) => (
                                        <LiteBalance
                                            key={balance.currency}
                                            balance={balance}
                                            settlementsDisclosure={showBalanceSettlements[balance.currency]}
                                            onClick={onSelectingBalance}
                                        />
                                    ))}
                                </div>
                            )}
                            {fiatBalances.length > 0 && (
                                <div className="flex flex-col items-center gap-3">
                                    <div className="flex w-full justify-between">
                                        <span className="text-sm sm:text-base">Fiat Currencies</span>
                                    </div>
                                    <div className="flex w-full text-xs sm:text-sm text-neutral-400 gap-3 px-3">
                                        <span className="flex-[1_1_0]">Currency</span>
                                        <span className="flex-[1_1_0] text-end">Balance</span>
                                        {nopBalance && (
                                            <div className="text-right flex-[1_1_0]">
                                                Balance {nopBalance.baseCurrency ? `(${nopBalance.baseCurrency})` : ''}
                                            </div>
                                        )}
                                    </div>
                                    <Divider className="bg-neutral-700" />
                                    {fiatBalances.map((balance) => (
                                        <LiteBalance
                                            key={balance.currency}
                                            balance={balance}
                                            settlementsDisclosure={showBalanceSettlements[balance.currency]}
                                            onClick={onSelectingBalance}
                                        />
                                    ))}
                                </div>
                            )}
                            {balanceTotals !== undefined && (
                                <div className="flex font-semibold pt-2">
                                    <div className="text-left flex-[1_1_0]">Total</div>
                                    <div
                                        className={cn(
                                            'flex flex-[2_1_0] relative items-center justify-center gap-2 text-right',
                                            {
                                                ['text-neutral-200']: balanceTotals === 0,
                                                ['text-brand-primary']: balanceTotals > 0,
                                                ['text-brand-red']: balanceTotals < 0
                                            }
                                        )}>
                                        <span className="flex-[1_1_0]">{formatQty.format(balanceTotals)}</span>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
                {status === 'CLOSED' && (
                    <div className="flex flex-col gap-2 p-2 justify-center items-center h-full text-xs sm:text-sm text-center text-neutral-400">
                        <div className="italic">Unable to fetch live balances</div>
                        <div className="italic">Please refresh the browser</div>
                    </div>
                )}
            </div>

            <ContextMenu
                x={contextMenuX}
                y={contextMenuY}
                visible={contextMenuVisible}
                onClose={handleCloseContextMenu}>
                {!isSameSettlementDate && showSettlements && (
                    <ContextMenuItem
                        onClick={() => {
                            const newShowBalanceSettlements = { ...showBalanceSettlements };
                            newShowBalanceSettlements[selectedBalanceCurrency] =
                                !showBalanceSettlements[selectedBalanceCurrency];
                            setShowBalanceSettlements(newShowBalanceSettlements);
                        }}>
                        <MdAutoAwesomeMotion />
                        <span>{!showBalanceSettlements[selectedBalanceCurrency] ? 'Show' : 'Hide'} Settlements</span>
                    </ContextMenuItem>
                )}
                <ContextMenuItem
                    onClick={() => {
                        setInitFrom('');
                        setInitTo(selectedBalanceCurrency);
                        handlers.toggle();
                    }}>
                    <MdAddCircle />
                    <span>Buy {selectedBalanceCurrency}</span>
                </ContextMenuItem>
                <ContextMenuItem
                    className="hover:bg-brand-red"
                    onClick={() => {
                        setInitFrom(selectedBalanceCurrency);
                        setInitTo('');
                        handlers.toggle();
                    }}>
                    <MdRemoveCircle />
                    <span>Sell {selectedBalanceCurrency}</span>
                </ContextMenuItem>
            </ContextMenu>
            <LiteOrderModal initFrom={initFrom} initTo={initTo} opened={opened} handlers={handlers} />
        </Fragment>
    );
}
