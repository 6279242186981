/* eslint-disable */
export const protobufPackage = 'com.celertech.orderrouting.api.enums.contingency';

export enum ContingencyType {
    OCO = 1,
    IF_DONE = 2,
    LOOP_IF_DONE = 3,
    BRACKET = 4,
    UNRECOGNIZED = -1
}
