import WatchListModal from '@/components/modal/WatchListModal';
import instrumentConfig from '@/config/instruments';
import { ModalOperations } from '@/model/common';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectMarketPairs } from '@/state/reducers/marketPairSlice';
import {
    addSubscription,
    selectCurrentWatchList,
    selectTraderWatchLists,
    TraderMarket
} from '@/state/reducers/traderMarketSlice';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { UseSizeReturn } from '@/utils/hooks/useSize';
import cn from 'classnames';
import { Dispatch, Fragment, SetStateAction, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import InstrumentSelector from './InstrumentSelector';
import WatchListActions from './WatchListActions';
import WatchListSwitch from './WatchListSwitch';

interface WatchListControlsProps {
    size: UseSizeReturn;
    showControls: boolean;
    modalMode: ModalOperations;
    setModalMode: (mode: ModalOperations) => void;
    setDndItems: Dispatch<
        SetStateAction<
            {
                id: number;
                market: TraderMarket;
            }[]
        >
    >;
}

const defaultCcyOption = {
    label: 'Instrument Selector',
    value: undefined,
    market: undefined
};

const WatchListControls = ({ size, showControls, modalMode, setModalMode, setDndItems }: WatchListControlsProps) => {
    const pairs = useAppSelector(selectMarketPairs);
    const dispatch = useAppDispatch();
    const watchLists = useAppSelector(selectTraderWatchLists);
    const selectedWatchList = useAppSelector(selectCurrentWatchList);

    const watchListDisclosure = useDisclosure(false);

    const watchList = useMemo(() => selectedWatchList || watchLists[0], [watchLists, selectedWatchList]);
    const addedInstruments = useMemo(() => watchList.markets.map((market) => market.celer), [watchList.markets]);

    const instrumentOptions = useMemo(() => {
        const options = pairs.map((instrument) => ({
            label: instrument.show,
            value: instrument.celer,
            market: instrument,
            config: instrumentConfig[instrument.celer],
            added: addedInstruments.includes(instrument.celer)
        }));

        options.sort((a, b) => (a.added === b.added ? 0 : a.added ? -1 : 1));

        return options;
    }, [watchList.markets, addedInstruments, pairs]);

    const { shouldFullWidth } = useMemo(() => {
        if (!size) return { shouldFullWidth: false };
        else if (size.width < 345) return { shouldFullWidth: true };
        return { shouldFullWidth: false };
    }, [size]);

    return (
        <Fragment>
            <div
                className={cn('flex flex-wrap gap-2 justify-between items-center text-neutral-200 border-neutral-700', {
                    hidden: !showControls,
                    'p-2 border-b': showControls
                })}>
                <div className="flex flex-wrap-reverse gap-2">
                    <WatchListSwitch parentSize={size} setItems={setDndItems} />
                    <div
                        className={cn('flex items-center gap-2', {
                            'w-full': shouldFullWidth
                        })}>
                        <div
                            title="Add New Watchlist"
                            className="flex whitespace-nowrap cursor-pointer shadow-md bg-brand-background-dark border border-neutral-700 hover:border-neutral-600 h-full px-2 py-1"
                            onClick={() => {
                                setModalMode('Create');
                                watchListDisclosure[1].toggle();
                            }}>
                            <span className="text-xs leading-5">New Watchlist</span>
                        </div>
                        <WatchListActions
                            parentSize={size}
                            onEdit={() => {
                                setModalMode('Edit');
                                watchListDisclosure[1].toggle();
                            }}
                            onDelete={() => {
                                setModalMode('Delete');
                                watchListDisclosure[1].toggle();
                            }}
                            deleteDisabled={watchLists.length === 1}
                        />
                    </div>
                </div>
                <InstrumentSelector
                    parentSize={size}
                    watchListId={watchList.id}
                    selected={defaultCcyOption}
                    options={instrumentOptions}
                    onSubmit={(market) => {
                        dispatch(addSubscription({ id: watchList.id, market }));
                        setDndItems((items) => [...items, { id: uuidv4(), market }]);
                    }}
                    onRemove={(market) => setDndItems((items) => items.filter((i) => i.market.celer !== market.celer))}
                />
            </div>
            <WatchListModal
                mode={modalMode}
                opened={watchListDisclosure[0]}
                handlers={watchListDisclosure[1]}
                setItems={setDndItems}
            />
        </Fragment>
    );
};

export default WatchListControls;
