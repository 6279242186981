import { createChannel, createClient, Metadata } from 'nice-grpc-web';
import { v4 as uuidv4 } from 'uuid';
import { User } from '../state/reducers/authSlice';

import { FindAllOrderSnapshotsByOrderId } from '@/compiled_proto/com/celertech/marketmerchant/api/order/UpstreamOrderProto';
import { ContingencyType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/ContingencyTypeProto';
import { ProductType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/ProductTypeProto';
import instrumentConfig from '@/config/instruments';
import { findSnapshotsFromAllOrders, parseBlotterOrder, parseOrderHistory } from '@/helpers/blotterHelper';
import { store } from '@/state/store';
import { parseNotificationSettings, toastError } from '@/utils/hooks/useToast';
import { checkiOSDevice } from '@/utils/ios';
import { HandlingInstruction } from '../compiled_proto/com/celertech/marketdata/api/enums/HandlingInstructionProto';
import { AccountType } from '../compiled_proto/com/celertech/orderrouting/api/enums/AccountTypeProto';
import { PegPriceType } from '../compiled_proto/com/celertech/orderrouting/api/enums/PegPriceTypeProto';
import {
    HistoricOrderSearchRequest,
    OrderServiceClient,
    OrderServiceDefinition
} from '../compiled_proto/com/celertech/orderrouting/api/notification/OrderServiceProto';
import { KeyValue } from '../compiled_proto/com/celertech/orderrouting/api/order/OrderMetadata';
import {
    AmendOrderRequest,
    CancelOrderRequest,
    CreateFxListOrder,
    CreateFxOrderRequest,
    CreateFxStrategyLegOrder
} from '../compiled_proto/com/celertech/orderrouting/api/order/UpstreamOrderProto';
import { OrderType } from '../compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import { Side } from '../compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import { TimeInForceType } from '../compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import {
    ValueDateServiceClient,
    ValueDateServiceDefinition
} from '../compiled_proto/com/celertech/staticdata/api/valuedates/ValueDateServiceProto';
import { getTradingDayStart } from '../helpers/dateHelper';
import { BlotterItem, OrderHistory } from '../model/blotters';
import { logToServer } from './LogService';

const CHANNEL_URL = window.config.integration.celertech.rest;

const exchangeCode = window.config.integration.celertech.exchangeCode || 'XCEL';

const channel = createChannel(CHANNEL_URL);

export async function getValueDate(securityId: string, credentials: User, tenorCode: 'SP' | 'TOM' = 'SP') {
    // Value data server
    const valueDateServiceClient: ValueDateServiceClient = createClient(ValueDateServiceDefinition, channel);

    // Receive the valueData to be used for order submission
    const valueEvent = await valueDateServiceClient.getValueDateForFxSecurity(
        { exchangeCode, securityId: securityId, tenorCode },
        { metadata: Metadata({ 'authorization-token': credentials.authToken }) }
    );
    const valueDate = valueEvent.fxValueDateResponse?.valueDate;
    return valueDate;
}

interface MarketOrderArguments {
    spotPrice?: number;
    securityId: string;
    side: Side;
    quantity: number;
    currencyOut: string;
    timeInForce: TimeInForceType;
}

export async function submitMarketOrder(args: MarketOrderArguments, credentials: User, currentAccount?: string) {
    rejectOrderIfAppleDevice(async () => {
        const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);
        const config = instrumentConfig[args.securityId];

        let marketExchangeCode = exchangeCode;
        let marketSettlementType = 'SP';
        let marketProductType = ProductType.SPOT;

        if (['Index', 'Commodity'].includes(config?.type)) {
            marketExchangeCode = 'XCEL';
            marketSettlementType = 'TOM';
            marketProductType = ProductType.CFD;
        }

        // Retrieve value data for security
        const valueDate = await getValueDate(args.securityId, credentials, marketSettlementType as 'SP' | 'TOM');

        // Market order
        const orderRequestMarket = CreateFxOrderRequest.fromPartial({
            clientRequestId: uuidv4(),
            clientOrderId: uuidv4(),
            exchangeIdSource: 'MIC',
            exchange: marketExchangeCode,
            securityIdSource: 'EXCHANGE',
            securityCode: args.securityId,
            securityId: args.securityId,
            accountType: AccountType.CLIENT,
            account: currentAccount || credentials.accounts[0].code,
            side: args.side,
            qty: args.quantity,
            currency: args.currencyOut, // does quantity relate to base or quote
            orderType: OrderType.MARKET,
            timeInForce: args.timeInForce,
            handlingInstruction: HandlingInstruction.AUTOMATED_NO_BROKER,
            // quoteId: quoteId,
            productType: marketProductType,
            leg: [
                CreateFxStrategyLegOrder.fromPartial({
                    legForwardPts: 0,
                    legValueDate: valueDate,
                    qty: args.quantity,
                    settlementType: marketSettlementType,
                    side: args.side,
                    spotPrice: args.spotPrice,
                    underlyingCode: args.securityId,
                    underlyingSecurityId: args.securityId
                })
            ], // See ts-proto useOptionals
            checkpointTimestamp: [],
            orderMetadata: [{ key: 'SLIPPAGE', value: '0' } as KeyValue],
            pegPriceType: PegPriceType.LAST_PEG
        });

        const orderSubmissionResponse = await orderServiceClient.createFxOrderRequest(orderRequestMarket, {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        });

        logToServer(
            'info',
            JSON.stringify({
                message: `User [${credentials.username}] has requested a Market Order for Account [${
                    currentAccount || credentials.accounts[0].code
                }].`,
                requestBody: orderRequestMarket,
                responseBody: orderSubmissionResponse
            })
        );

        // console.log('Order Submission Response', { orderRequestMarket, orderSubmissionResponse });
        return orderSubmissionResponse;
    });
}

interface LimitOrderArguments extends MarketOrderArguments {
    limitPrice: number;
    slippage: string;
}

export async function submitLimitOrder(args: LimitOrderArguments, credentials: User, currentAccount?: string) {
    rejectOrderIfAppleDevice(async () => {
        const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);
        const config = instrumentConfig[args.securityId];

        let marketExchangeCode = exchangeCode;
        let marketSettlementType = 'SP';
        let marketProductType = ProductType.SPOT;

        if (['Index', 'Commodity'].includes(config?.type)) {
            marketExchangeCode = 'XCEL';
            marketSettlementType = 'TOM';
            marketProductType = ProductType.CFD;
        }

        // Retrieve value data for security
        const valueDate = await getValueDate(args.securityId, credentials, marketSettlementType as 'SP' | 'TOM');

        const slippage = args.slippage === 'NaN' ? '0' : args.slippage;
        // Limit order
        const orderRequestLimit = CreateFxOrderRequest.fromPartial({
            clientRequestId: uuidv4(),
            clientOrderId: uuidv4(),
            exchangeIdSource: 'MIC',
            exchange: marketExchangeCode,
            securityIdSource: 'EXCHANGE',
            securityCode: args.securityId,
            securityId: args.securityId,
            accountType: AccountType.CLIENT,
            account: currentAccount || credentials.accounts[0].code,
            side: args.side,
            qty: args.quantity,
            price: args.limitPrice,
            currency: args.currencyOut,
            orderType: OrderType.LIMIT,
            timeInForce: args.timeInForce,
            handlingInstruction: HandlingInstruction.AUTOMATED_NO_BROKER,
            // quoteId: quoteId,
            productType: marketProductType,
            leg: [
                CreateFxStrategyLegOrder.fromPartial({
                    underlyingCode: args.securityId,
                    underlyingSecurityId: args.securityId,
                    legValueDate: valueDate,
                    side: args.side,
                    qty: args.quantity,
                    price: args.limitPrice,
                    settlementType: marketSettlementType
                })
            ], // See ts-proto useOptionals
            checkpointTimestamp: [],
            orderMetadata: [
                { key: 'SLIPPAGE', value: slippage } as KeyValue
                // { key: 'QUOTE_ID', value: quoteId } as KeyValue
            ],
            pegPriceType: PegPriceType.LAST_PEG
        });

        const orderSubmissionResponse = await orderServiceClient.createFxOrderRequest(orderRequestLimit, {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        });

        logToServer(
            'info',
            JSON.stringify({
                message: `User [${credentials.username}] has requested a Limit Order for Account [${
                    currentAccount || credentials.accounts[0].code
                }].`,
                requestBody: orderRequestLimit,
                responseBody: orderSubmissionResponse
            })
        );

        return orderSubmissionResponse;
    });
}

export async function cancelLimitOrder(args: Partial<CancelOrderRequest>, credentials: User) {
    rejectOrderIfAppleDevice(async () => {
        const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);

        const orderRequestLimit = CancelOrderRequest.fromPartial({
            clientRequestId: uuidv4(),
            orderId: args.orderId,
            clOrdId: args.clOrdId,
            origClOrdId: args.origClOrdId,
            onBehalfOfCompId: args.onBehalfOfCompId,
            deliverToCompId: args.deliverToCompId,
            checkpointTimestamp: args.checkpointTimestamp,
            onBehalfOfUsername: args.onBehalfOfUsername,
            parentOrderId: args.parentOrderId
        });

        const cancelOrderSubmissionResponse = await orderServiceClient.cancelOrderRequest(orderRequestLimit, {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        });

        logToServer(
            'info',
            JSON.stringify({
                message: `User [${credentials.username}] has requested to Cancel a Limit Order for OrderId [${args.orderId}].`,
                requestBody: orderRequestLimit,
                responseBody: cancelOrderSubmissionResponse
            })
        );

        return cancelOrderSubmissionResponse;
    }, 'cancel');
}

export async function amendOrder(args: Partial<AmendOrderRequest>, credentials: User) {
    return await rejectOrderIfAppleDevice(async () => {
        const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);

        const amendOrderRequest = AmendOrderRequest.fromPartial({
            clientRequestId: uuidv4(),
            orderId: args.orderId,
            clOrdId: args.clOrdId,
            account: args.account,
            qty: args.qty,
            price: args.price,
            stopPrice: args.stopPrice
            // origClOrdId: args.origClOrdId,
            // orderText: args.orderText,
            // giveIn: args.giveIn,
            // giveUp: args.giveUp,
            // onBehalfOfCompId: args.onBehalfOfCompId,
            // deliverToCompId: args.deliverToCompId,
            // quoteId: args.quoteId,
            // checkpointTimestamp: args.checkpointTimestamp,
            // orderMetadata: args.orderMetadata,
            // timeInForceExpiry: args.timeInForceExpiry,
            // executionInstruction: args.executionInstruction,
            // minQty: args.minQty,
            // maxShow: args.maxShow,
            // commission: args.commission,
            // instructionsMessage: args.instructionsMessage
        });

        const amendOrderSubmissionResponse = await orderServiceClient.amendOrderRequest(amendOrderRequest, {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        });

        logToServer(
            'info',
            JSON.stringify({
                message: `User [${credentials.username}] has requested to Amend an Order for OrderId [${args.orderId}].`,
                requestBody: amendOrderRequest,
                responseBody: amendOrderSubmissionResponse
            })
        );

        return amendOrderSubmissionResponse;
    }, 'amend');
}

interface StopOrderArguments extends MarketOrderArguments {
    limitPrice: number;
    stopPrice: number;
    slippage: string;
    timeInForce: TimeInForceType;
    orderType: OrderType;
}

export async function submitStopOrder(args: StopOrderArguments, credentials: User, currentAccount?: string) {
    rejectOrderIfAppleDevice(async () => {
        const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);
        const config = instrumentConfig[args.securityId];

        let marketExchangeCode = exchangeCode;
        let marketSettlementType = 'SP';
        let marketProductType = ProductType.SPOT;

        if (['Index', 'Commodity'].includes(config?.type)) {
            marketExchangeCode = 'XCEL';
            marketSettlementType = 'TOM';
            marketProductType = ProductType.CFD;
        }

        // Retrieve value data for security
        const valueDate = await getValueDate(args.securityId, credentials, marketSettlementType as 'SP' | 'TOM');
        const isStopMarket = args.orderType === OrderType.STOP_MARKET;

        // Stop order
        const orderRequestStop = createStopOrderPayload({
            ...args,
            marketExchangeCode,
            marketSettlementType,
            marketProductType,
            valueDate,
            credentials,
            currentAccount,
            isStopMarket
        });

        const orderSubmissionResponse = await orderServiceClient.createFxOrderRequest(orderRequestStop, {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        });

        logToServer(
            'info',
            JSON.stringify({
                message: `User [${credentials.username}] has requested a Stop Order for Account [${
                    currentAccount || credentials.accounts[0].code
                }].`,
                requestBody: orderRequestStop,
                responseBody: orderSubmissionResponse
            })
        );

        return orderSubmissionResponse;
    });
}

interface OcoOrderArguments extends MarketOrderArguments {
    order1: {
        stopPrice: number;
        stopLimitPrice: number;
        limitPrice: number;
        type: { label: string; value: OrderType };
        quantity: number;
    };
    order2: {
        stopPrice: number;
        stopLimitPrice: number;
        limitPrice: number;
        type: { label: string; value: OrderType };
        slippage: string;
        quantity: number;
    };
    timeInForce: TimeInForceType;
}

export async function submitOcoOrder(args: OcoOrderArguments, credentials: User, currentAccount?: string) {
    rejectOrderIfAppleDevice(async () => {
        const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);
        const config = instrumentConfig[args.securityId];

        let marketExchangeCode = exchangeCode;
        let marketSettlementType = 'SP';
        let marketProductType = ProductType.SPOT;

        if (['Index', 'Commodity'].includes(config?.type)) {
            marketExchangeCode = 'XCEL';
            marketSettlementType = 'TOM';
            marketProductType = ProductType.CFD;
        }

        // Retrieve value data for security
        const valueDate = await getValueDate(args.securityId, credentials, marketSettlementType as 'SP' | 'TOM');

        const order2Payload = {
            ...args.order2,
            isStopMarket: args.order2.type.value === OrderType.STOP_MARKET
        };

        const firstOrder = createLimitOrderPayload({
            ...args,
            ...args.order1,
            marketExchangeCode,
            marketSettlementType,
            marketProductType,
            valueDate,
            credentials,
            currentAccount,
            limitPrice: args.order1.limitPrice,
            slippage: '0'
        });

        const secondOrder = createStopOrderPayload({
            ...args,
            ...args.order2,
            marketExchangeCode,
            marketSettlementType,
            marketProductType,
            valueDate,
            credentials,
            currentAccount,
            stopPrice: order2Payload.stopPrice,
            limitPrice: order2Payload.stopLimitPrice,
            slippage: order2Payload.slippage,
            orderType: args.order2.type.value,
            isStopMarket: order2Payload.isStopMarket,
            spotPrice: args.spotPrice
        });

        const orderRequestOco = CreateFxListOrder.fromPartial({
            clientRequestId: uuidv4(),
            listID: `STRATEGYORDER:PARENT:${uuidv4()}`,
            contingencyType: ContingencyType.OCO,
            strategyOrders: [firstOrder, secondOrder]
        });

        const orderSubmissionResponse = await orderServiceClient.createStrategyOrderRequest(orderRequestOco, {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        });

        logToServer(
            'info',
            JSON.stringify({
                message: `User [${credentials.username}] has requested a OCO Order for Account [${
                    currentAccount || credentials.accounts[0].code
                }].`,
                requestBody: orderRequestOco,
                responseBody: orderSubmissionResponse
            })
        );

        return orderSubmissionResponse;
    });
}

export async function getBlotterOrders(credentials: User) {
    const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);

    // Get all orders
    const getAllOrdersRequest = await orderServiceClient.findAllFxOrders(
        { clientRequestId: uuidv4() },
        { metadata: Metadata({ 'authorization-token': credentials.authToken }) }
    );

    const items: BlotterItem[] = [];

    for (const orderUpdate of getAllOrdersRequest.orders) {
        const { fxOrderSnapshotDownstreamEvent, createOrderRequestRejectDownstreamEvent } = orderUpdate;

        if (fxOrderSnapshotDownstreamEvent && !createOrderRequestRejectDownstreamEvent?.rejectReason) {
            items.push(parseBlotterOrder(fxOrderSnapshotDownstreamEvent));
        }
    }

    return items;
}

export async function getBlotterTrades(credentials: User, blotterOrders: BlotterItem[]) {
    const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);
    return await findSnapshotsFromAllOrders({ historicOrders: blotterOrders, credentials, client: orderServiceClient });
}

export async function searchBlotter(credentials: User, criteria: any, startDate?: Date, endDate?: Date) {
    // Setting citeria to any because it only accepts number, not string as its stated. Else it would just return all without filtering.
    const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);

    const currentTradingDay = getTradingDayStart();

    const orderRequestMarket = HistoricOrderSearchRequest.fromPartial({
        startDate: (startDate || currentTradingDay).getTime().toString(),
        endDate: (endDate || new Date()).getTime().toString(),
        assetType: 'FX',
        executionType: criteria === 0 ? [] : [criteria]
    });

    const { orders } = await orderServiceClient.findAllOrdersBySearch(orderRequestMarket, {
        metadata: Metadata({ 'authorization-token': credentials.authToken })
    });

    return await findSnapshotsFromAllOrders({ historicOrders: orders, credentials, client: orderServiceClient });
}

interface FindOrderHistoryArguments {
    orderId: string;
}

export async function findOrderHistory(args: FindOrderHistoryArguments, credentials: User) {
    // Setting citeria to any because it only accepts number, not string as its stated. Else it would just return all without filtering.
    const orderServiceClient: OrderServiceClient = createClient(OrderServiceDefinition, channel);

    const orderRequestMarket = FindAllOrderSnapshotsByOrderId.fromPartial({
        clientRequestId: uuidv4(),
        orderId: args.orderId
    });

    const orderHistories = await orderServiceClient.findAllOrderSnapshotsByOrderId(orderRequestMarket, {
        metadata: Metadata({
            'authorization-token': credentials.authToken
        })
    });

    // console.log({
    //     API: 'findAllOrderSnapshotsByOrderId',
    //     request: orderRequestMarket,
    //     response: orderHistories.orders
    // });

    const items: OrderHistory[] = [];

    for (const orderEvent of orderHistories.orders) {
        const { fxOrderSnapshotDownstreamEvent } = orderEvent;
        if (fxOrderSnapshotDownstreamEvent) items.push(parseOrderHistory(fxOrderSnapshotDownstreamEvent));
    }

    return items;
}

interface CreateLimitOrderPayloadArgs {
    securityId: string;
    side: Side;
    quantity: number;
    currencyOut: string;
    timeInForce: TimeInForceType;
    marketExchangeCode: string;
    marketSettlementType: string;
    marketProductType: ProductType;
    valueDate?: string;
    limitPrice: number;
    slippage: string;
    credentials: User;
    currentAccount?: string;
}

const createLimitOrderPayload = (args: CreateLimitOrderPayloadArgs) => {
    const slippage = args.slippage === 'NaN' ? '0' : args.slippage;
    return CreateFxOrderRequest.fromPartial({
        clientRequestId: uuidv4(),
        clientOrderId: uuidv4(),
        exchangeIdSource: 'MIC',
        exchange: args.marketExchangeCode,
        securityIdSource: 'EXCHANGE',
        securityCode: args.securityId,
        securityId: args.securityId,
        accountType: AccountType.CLIENT,
        account: args.currentAccount || args.credentials.accounts[0].code,
        side: args.side,
        qty: args.quantity,
        price: args.limitPrice,
        currency: args.currencyOut,
        orderType: OrderType.LIMIT,
        timeInForce: args.timeInForce,
        handlingInstruction: HandlingInstruction.AUTOMATED_NO_BROKER,
        // quoteId: quoteId,
        productType: args.marketProductType,
        leg: [
            CreateFxStrategyLegOrder.fromPartial({
                underlyingCode: args.securityId,
                underlyingSecurityId: args.securityId,
                legValueDate: args.valueDate,
                side: args.side,
                qty: args.quantity,
                price: args.limitPrice,
                settlementType: args.marketSettlementType
            })
        ], // See ts-proto useOptionals
        checkpointTimestamp: [],
        orderMetadata: [
            { key: 'SLIPPAGE', value: slippage } as KeyValue
            // { key: 'QUOTE_ID', value: quoteId } as KeyValue
        ],
        pegPriceType: PegPriceType.LAST_PEG
    });
};

interface CreateStopOrderPayloadArgs {
    securityId: string;
    side: Side;
    quantity: number;
    currencyOut: string;
    timeInForce: TimeInForceType;
    marketExchangeCode: string;
    marketSettlementType: string;
    marketProductType: ProductType;
    valueDate?: string;
    stopPrice: number;
    limitPrice: number;
    slippage: string;
    orderType: OrderType;
    isStopMarket: boolean;
    spotPrice?: number;
    credentials: User;
    currentAccount?: string;
}

const createStopOrderPayload = (args: CreateStopOrderPayloadArgs) => {
    const slippage = args.slippage === 'NaN' ? '0' : args.slippage;

    return CreateFxOrderRequest.fromPartial({
        clientRequestId: uuidv4(),
        clientOrderId: uuidv4(),
        exchangeIdSource: 'MIC',
        exchange: args.marketExchangeCode,
        securityIdSource: 'EXCHANGE',
        securityCode: args.securityId,
        securityId: args.securityId,
        accountType: AccountType.CLIENT,
        account: args.currentAccount || args.credentials.accounts[0].code,
        side: args.side,
        qty: args.quantity,
        price: args.isStopMarket ? args.spotPrice : args.limitPrice,
        stopPrice: args.stopPrice,
        currency: args.currencyOut,
        orderType: args.orderType,
        timeInForce: args.timeInForce,
        handlingInstruction: HandlingInstruction.AUTOMATED_NO_BROKER,
        // quoteId: quoteId,
        productType: args.marketProductType,
        leg: [
            CreateFxStrategyLegOrder.fromPartial({
                legForwardPts: 0,
                legValueDate: args.valueDate,
                qty: args.quantity,
                settlementType: args.marketSettlementType,
                side: args.side,
                price: args.isStopMarket ? args.spotPrice : args.limitPrice,
                spotPrice: args.isStopMarket ? args.spotPrice : undefined,
                underlyingCode: args.securityId,
                underlyingSecurityId: args.securityId
            })
        ], // See ts-proto useOptionals
        checkpointTimestamp: [],
        orderMetadata: [{ key: 'SLIPPAGE', value: args.isStopMarket ? '0' : slippage } as KeyValue],
        pegPriceType: PegPriceType.LAST_PEG
    });
};

// CreateFxOrderRequest.fromPartial({
//     clientOrderId: uuidv4(),
//     exchangeIdSource: 'MIC',
//     exchange: marketExchangeCode,
//     securityIdSource: 'EXCHANGE',
//     securityCode: args.securityId,
//     securityId: args.securityId,
//     accountType: AccountType.CLIENT,
//     account: currentAccount || credentials.accounts[0].code,
//     side: args.side,
//     qty: args.quantity,
//     price: order1Payload.isStopMarket ? args.spotPrice : args.limitPrice1,
//     stopPrice: order1Payload.stopPrice,
//     currency: args.currencyOut,
//     orderType: order1Payload.orderType,
//     timeInForce: args.timeInForce,
//     handlingInstruction: HandlingInstruction.AUTOMATED_NO_BROKER,
//     // quoteId: quoteId,
//     productType: marketProductType,
//     leg: [
//         CreateFxStrategyLegOrder.fromPartial({
//             legForwardPts: 0,
//             legValueDate: valueDate,
//             qty: args.quantity,
//             settlementType: marketSettlementType,
//             side: args.side,
//             price: order1Payload.isStopMarket ? args.spotPrice : args.limitPrice1,
//             spotPrice: order1Payload.isStopMarket ? args.spotPrice : undefined,
//             underlyingCode: args.securityId,
//             underlyingSecurityId: args.securityId
//         })
//     ], // See ts-proto useOptionals
//     checkpointTimestamp: [],
//     orderMetadata: [
//         { key: 'SLIPPAGE', value: order1Payload.isStopMarket ? '0' : args.slippage1 } as KeyValue
//     ],
//     pegPriceType: PegPriceType.LAST_PEG
//     // "bookAsFilled": false,
//     // "clientRequestId": "d68c43ae-3e2d-41e4-adfc-2b9fa6f75a23",
//     // "maxShow": 0,
//     // "orderType": 2,
//     // "orderPartiesGroup": [
//     //   {
//     //     "partyId": "ext-dnh@gc.exchange",
//     //     "partyIdSource": 6,
//     //     "partyRole": 20
//     //   }
//     // ],
// })

const rejectOrderIfAppleDevice = (callback, action = 'order') => {
    if (!checkiOSDevice()) {
        callback();
    } else {
        const state = store.getState();
        const isOrder = action === 'order';
        const isCancel = action === 'cancel';
        const isAmend = action === 'amend';

        toastError({
            body: (
                <div>
                    <p>
                        {isOrder &&
                            'Placing orders on an Apple device is temporarily unavailable, please reach out to support.'}
                        {isCancel &&
                            'Cancelling orders on an Apple device is temporarily unavailable, please reach out to support.'}
                        {isAmend &&
                            'Amending orders on an Apple device is temporarily unavailable, please reach out to support.'}
                    </p>
                </div>
            ),
            title: `${isOrder ? 'Order' : 'Action'} Rejected`,
            persist: true,
            toastConfig: parseNotificationSettings(state.settings.notifications)
        });
    }
};
