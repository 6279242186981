import { logToServer } from '@/services/LogService';
import { Logger } from '@/utils/logger';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { doLogout, selectCredentials } from '../../state/reducers/authSlice';
import { formatPlural } from '../../utils/format';
import useToast from '../../utils/hooks/useToast';
import Divider from '../common/Divider';
import Modal, { ModalHeader, ModalProps, ModalTitle } from './Modal';

const expirationDuration = +(window.config.sessionExpirationTimer || 60000) - 1000;

const IdleModal = (props: ModalProps) => {
    const { opened, handlers } = props;

    const [toastError, toastSuccess] = useToast();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const credentials = useAppSelector(selectCredentials);
    const [expirationTimer, setExpirationTimer] = useState<number>(expirationDuration);

    const onLogout = async (forced = false) => {
        Logger({
            title: `Idle modal initiated logout.`,
            callback: () => {}
        });
        try {
            if (credentials) {
                await dispatch(doLogout({ credentials }) as any).unwrap();
            }
        } catch (rejectedValueOrSerializedError) {
            console.warn('Could not actively logout, due to timed out session token, clearing persisted credentials');
        }
        navigate('/login');
        if (forced) {
            // handle error here
            toastError({
                body: 'Logged out due to inactivity',
                title: 'Service disconnected'
            });
        }
        logToServer('error', `User [${credentials?.username}] has been logged out due to inactivity.`);
    };

    useEffect(() => {
        if (opened) setExpirationTimer(expirationDuration);
    }, [opened]);

    useEffect(() => {
        if (opened) {
            const timer = setTimeout(() => setExpirationTimer(expirationTimer - 1000), 1000);
            if (expirationTimer <= 0) {
                clearTimeout(timer);
                handlers.close();
                onLogout(true);
            }
        }
    }, [opened, expirationTimer]);

    const timeLeft = useMemo(
        () => ({
            minutes: Math.floor(expirationTimer / 60000),
            seconds: (expirationTimer % 60000) / 1000
        }),
        [expirationTimer]
    );

    return (
        <Modal {...props} className="h-auto" size="max-w-md">
            <ModalHeader>
                <ModalTitle>Session Expiration Warning</ModalTitle>
            </ModalHeader>
            <Divider />
            <div className="text-sm sm:text-base text-neutral-200 p-2 py-3 sm:p-4">
                <div className="font-bold">
                    Time Remaining:
                    {timeLeft.minutes ? formatPlural(`${timeLeft.minutes} minute`, timeLeft.minutes) : ''}{' '}
                    {timeLeft.seconds ? formatPlural(`${timeLeft.seconds} second`, timeLeft.seconds) : ''}{' '}
                </div>
                <div className="text-neutral-400 pt-1">
                    Due to inactivity, you will automatically be logged out. <br />
                    Do you want to stay logged in?
                </div>
            </div>
            <Divider />
            <div className="text-neutral-200 p-2 sm:p-4 text-sm flex space-x-2">
                <button
                    className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                    onClick={() => {
                        handlers.close();
                        onLogout();
                    }}>
                    Log Out
                </button>
                <button
                    className="rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light"
                    onClick={handlers.close}>
                    Stay Logged In
                </button>
            </div>
        </Modal>
    );
};

export default IdleModal;
