import { qtyFormatterConfig } from '@/config/config';
import { useAppSelector } from '@/state/hooks';
import { selectCurrentAccount } from '@/state/reducers/authSlice';
import { CollapsedBalances, selectBalanceStatus, selectNopBalance } from '@/state/reducers/balanceSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import usePreviousState from '@/utils/hooks/usePreviousState';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import Balance from './Balance';

interface BalancesProps {}

export default function Balances(props: BalancesProps) {
    const status = useAppSelector(selectBalanceStatus);
    const nopBalance = useAppSelector(selectNopBalance);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const formatQty = useNumberFormatter(qtyFormatterConfig);
    const [newRecords, setNewRecords] = useState<CollapsedBalances[]>([]);

    const balances = useMemo(() => nopBalance?.balances || [], [nopBalance]);
    const isSameSettlementDate = useMemo(() => {
        const flattenDates = balances.flatMap((balance) => balance.rows).map((row) => row.valueDate);
        const set = Array.from(new Set(flattenDates));
        return set.length === 1;
    }, [balances]);

    const nopBalanceAccountMatched = useMemo(() => {
        return currentAccount === nopBalance?.account;
    }, [currentAccount, nopBalance]);

    const balanceTotals = useMemo(() => {
        return balances?.reduce(
            (acc, curr) =>
                BigNumber(acc)
                    .plus((curr as CollapsedBalances).totalAmountInBaseCurrency)
                    .toNumber(),
            0
        );
    }, [balances]);

    const prevBalances = usePreviousState(balances);

    useDidUpdate(() => {
        if (nopBalance) {
            const records = nopBalance?.balances?.filter(
                (balance) => !prevBalances.find((prev) => prev.currency === balance.currency)
            );
            setNewRecords(records || []);
        }
    }, [nopBalance]);

    return (
        <div className="flex flex-col h-full text-gray-200">
            <div className="flex justify-between p-2 pb-0 text-sm px-3">
                <div className="text-left flex-[1_1_0]">Currency</div>
                <div className="flex flex-[2_1_0] relative justify-center gap-2">
                    <div className="text-right flex-[1_1_0]">Balance</div>
                    {nopBalance && nopBalanceAccountMatched && (
                        <div className="text-right flex-[1_1_0]">Balance ({nopBalance.baseCurrency})</div>
                    )}
                </div>
            </div>
            {status === 'OPEN' && nopBalanceAccountMatched && (
                <div className="w-full flex flex-col relative self-stretch grow basis-full overflow-y-auto px-2 py-1">
                    {balances.map((balance, index) => (
                        <Balance
                            key={index}
                            balance={balance}
                            isNewlyAdded={!!newRecords.find((record) => record.currency === balance.currency)}
                            isSameSettlementDate={!!isSameSettlementDate}
                        />
                    ))}
                    {balanceTotals !== undefined && (
                        <div className="flex font-semibold p-1 pt-2">
                            <div className="text-left flex-[1_1_0]">Total</div>
                            <div
                                className={cn(
                                    'flex flex-[2_1_0] relative items-center justify-center gap-2 text-right',
                                    {
                                        ['text-neutral-200']: balanceTotals === 0,
                                        ['text-brand-primary']: balanceTotals > 0,
                                        ['text-brand-red']: balanceTotals < 0
                                    }
                                )}>
                                <span className="flex-[1_1_0]">{formatQty.format(balanceTotals)}</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {status === 'CLOSED' && (
                <div className="flex flex-col gap-2 p-2 justify-center items-center h-full text-sm text-center text-neutral-400">
                    <div className="italic">Unable to fetch live balances</div>
                    <div className="italic">Please reach out to support</div>
                </div>
            )}
        </div>
    );
}
