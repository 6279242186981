import {
    DEFAULT_LAPTOP_LAYOUT,
    DEFAULT_LAYOUT,
    XplorTraderModules,
    xplorTraderLayoutConfig,
    xplorTraderTabIcon,
    xplorTraderTabList
} from '@/config/xplorTraderLayout';
import { useAppDispatch } from '@/state/hooks';
import {
    setXplorTraderDesktopLayoutNode,
    setXplorTraderLaptopLayoutNode
} from '@/state/reducers/xplorTraderLayoutSlice';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import useXplorTraderLayout from '@/utils/hooks/useXplorTraderLayout';
import _, { dropRight } from 'lodash';
import { useMemo } from 'react';
import { MdRefresh } from 'react-icons/md';
import {
    Corner,
    MosaicDirection,
    MosaicNode,
    MosaicParent,
    getNodeAtPath,
    getOtherDirection,
    getPathToCorner,
    updateTree
} from 'react-mosaic-component';
import TraderMobileTabNavigation from './TraderMobileTabNavigation';
import TraderTabletTabNavigation from './TraderTabletTabNavigation';

const defaultPropertyValues = xplorTraderTabList.filter((tab) => tab !== 'Notifications');

function getAllPropertyValues(obj): Array<string | number> {
    const values: any = [];

    function recursiveGetValues(obj) {
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                recursiveGetValues(obj[key]);
            } else {
                values.push(obj[key]);
            }
        }
    }

    recursiveGetValues(obj);
    return values;
}

export default function TraderFooter() {
    const screen = useScreenSizes();
    const { layout, default_layout } = useXplorTraderLayout(screen);

    return (
        <footer className="flex bg-brand-background text-neutral-200 text-xs h-16 p-0 sm:h-6 sm:p-0.5">
            {(screen.laptop || screen.desktop) && <TabNavigation screen={screen} layout={layout || default_layout} />}
            {screen.tablet && <TraderTabletTabNavigation />}
            {screen.mobile && <TraderMobileTabNavigation />}
        </footer>
    );
}

const TabNavigation = ({ screen, layout }: any) => {
    const dispatch = useAppDispatch();

    const removedModules = useMemo(() => {
        let propertyValues;
        if (typeof layout === 'string') propertyValues = [layout];
        else
            propertyValues = getAllPropertyValues(layout).filter(
                (el) => typeof el !== 'number' && !['row', 'column'].includes(el)
            );
        const difference = _.difference(defaultPropertyValues, propertyValues);
        // difference.sort();
        return difference;
    }, [layout]);

    return (
        <div className="flex gap-0.5">
            <div
                className="flex uppercase cursor-pointer bg-neutral-500 hover:bg-neutral-400 text-neutral-200 font-semibold px-2 gap-2 items-center"
                onClick={() => {
                    if (screen.laptop) dispatch(setXplorTraderLaptopLayoutNode(DEFAULT_LAPTOP_LAYOUT));
                    if (screen.desktop) dispatch(setXplorTraderDesktopLayoutNode(DEFAULT_LAYOUT));
                }}>
                <MdRefresh className="w-3.5 h-3.5" />
                Reset Layout
            </div>
            {removedModules.map((mod) => {
                const Icon = xplorTraderTabIcon[mod];
                const { title } = xplorTraderLayoutConfig[mod];

                return (
                    <div
                        key={mod}
                        className="flex uppercase cursor-pointer bg-brand-primary-dark hover:bg-brand-primary text-neutral-200 font-semibold px-2 gap-2 items-center"
                        onClick={() =>
                            addToTopRight(layout, mod, (currentNode) => {
                                if (screen.laptop) dispatch(setXplorTraderLaptopLayoutNode(currentNode));
                                if (screen.desktop) dispatch(setXplorTraderDesktopLayoutNode(currentNode));
                            })
                        }>
                        <Icon className="w-3.5 h-3.5" />
                        ADD {title}
                    </div>
                );
            })}
        </div>
    );
};

const addToTopRight = (layoutNode, moduleToDrop, callback) => {
    let currentNode = layoutNode;
    if (currentNode) {
        const path = getPathToCorner(currentNode, Corner.TOP_RIGHT);
        const parent = getNodeAtPath(currentNode, dropRight(path)) as MosaicParent<XplorTraderModules>;
        const destination = getNodeAtPath(currentNode, path) as MosaicNode<XplorTraderModules>;
        const direction: MosaicDirection = parent ? getOtherDirection(parent.direction) : 'row';

        let first: MosaicNode<XplorTraderModules>;
        let second: MosaicNode<XplorTraderModules>;
        if (direction === 'row') {
            first = destination;
            second = moduleToDrop;
        } else {
            first = moduleToDrop;
            second = destination;
        }

        currentNode = updateTree(currentNode, [
            {
                path,
                spec: {
                    $set: {
                        direction,
                        first,
                        second
                    }
                }
            }
        ]);
    } else {
        currentNode = moduleToDrop;
    }

    callback(currentNode);
};
