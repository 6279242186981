import CurrencyIcon from '@/components/common/CurrencyIcon';
import { qtyFormatterConfig } from '@/config/config';
import { CollapsedBalances } from '@/state/reducers/balanceSlice';
import { isSameUTCDay } from '@/utils/format';
import { useCurrency } from '@/utils/hooks/useCurrency';
import cn from 'classnames';
import { Fragment, useMemo } from 'react';
import { useNumberFormatter } from 'react-aria';

interface LiteBalanceProps {
    balance: CollapsedBalances;
    settlementsDisclosure?: boolean;
    onClick: (e: any, ccy: CollapsedBalances) => void;
}

const LiteBalance = ({ balance, settlementsDisclosure, onClick }: LiteBalanceProps) => {
    const formatQty = useNumberFormatter(qtyFormatterConfig);
    const { longName, show } = useCurrency(balance.currency);

    const balanceColor = useMemo(() => {
        if (balance.totalAmount === 0) return 'text-neutral-200';
        else if (balance.totalAmount > 0) return 'text-brand-primary';
        else return 'text-brand-red';
    }, [balance.totalAmount]);

    const showSettlements = useMemo(() => {
        const hasSettlements = balance.rows.length > 0;
        const allSettlementsAreToday = balance.rows.every((row) => isSameUTCDay(row.valueDate));
        return hasSettlements && !allSettlementsAreToday;
    }, [balance.rows]);

    return (
        <div className="flex flex-col w-full items-center">
            <div
                className="flex w-full items-center p-1 px-3 rounded-sm text-xs sm:text-sm hover:bg-neutral-700 focus:bg-neutral-700 cursor-pointer"
                onClick={(e) => onClick(e, balance)}>
                <div className="flex flex-[1_1_0] items-center gap-2">
                    <CurrencyIcon ccy={show.toLowerCase()} />
                    <div className="flex flex-col">
                        <span>{longName || balance.currency}</span>
                        <span className="text-neutral-400 text-2xs sm:text-xs">{balance.currency}</span>
                    </div>
                </div>
                <div
                    className={cn('flex flex-[2_1_0] relative items-center justify-center gap-2 text-right', {
                        [balanceColor]: true
                    })}>
                    <span className="flex-[1_1_0]">{formatQty.format(balance.totalAmount)}</span>
                    {balance.totalAmountInBaseCurrency && (
                        <span className="flex-[1_1_0]">{formatQty.format(balance.totalAmountInBaseCurrency)}</span>
                    )}
                </div>
            </div>
            <div className="flex flex-col w-full gap-1 p-1 px-3">
                {showSettlements && settlementsDisclosure && (
                    <Fragment>
                        <div className="flex justify-between text-2xs sm:text-xs text-neutral-400">
                            <div className="text-left flex-[1_1_0] whitespace-nowrap">Settlement Date</div>
                            <div className="flex flex-[2_1_0] relative items-center justify-center gap-2 text-right">
                                <div className="text-right flex-[1_1_0]">-</div>
                                <div className="text-right flex-[1_1_0]">-</div>
                            </div>
                        </div>
                        {balance.rows.map((row, index) => (
                            <div key={index} className="flex justify-between text-sm">
                                <div className="text-left flex-[1_1_0]">{row.valueDate}</div>
                                <div
                                    className={cn(
                                        'flex flex-[2_1_0] relative items-center justify-center gap-2 text-right',
                                        {
                                            ['text-neutral-200']: row.amount === 0,
                                            ['text-brand-primary']: row.amount > 0,
                                            ['text-brand-red']: row.amount < 0
                                        }
                                    )}>
                                    <div className="text-right flex-[1_1_0]">{formatQty.format(row.amount)}</div>
                                    <div className="text-right flex-[1_1_0]">
                                        {formatQty.format(row.amountInBaseCurrency)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default LiteBalance;
