import { ModalOperations } from '@/model/common';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import {
    TraderMarket,
    selectCurrentWatchList,
    selectShouldReinstateWatchlist,
    selectTraderWatchLists,
    setShouldReinstateWatchlist
} from '@/state/reducers/traderMarketSlice';
import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import useSize from '@/utils/hooks/useSize';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DndTickers from './DndTickers';
import WatchListControls from './WatchlistControls';

interface TickersModuleBodyProps {
    showControls: boolean;
    modalMode: ModalOperations;
    setModalMode: (mode: ModalOperations) => void;
    orderDisclosure: UseDisclosureReturn;
}

const TickersModuleBody = ({ showControls, modalMode, setModalMode, orderDisclosure }: TickersModuleBodyProps) => {
    const dispatch = useAppDispatch();
    const watchLists = useAppSelector(selectTraderWatchLists);
    const selectedWatchList = useAppSelector(selectCurrentWatchList);
    const shouldReinstateWatchlist = useAppSelector(selectShouldReinstateWatchlist);

    const moduleBodyRef = useRef<HTMLDivElement>(null);
    const size = useSize(moduleBodyRef);

    const watchList = useMemo(() => selectedWatchList || watchLists[0], [watchLists, selectedWatchList]);

    const [dndItems, setDndItems] = useState<{ id: number; market: TraderMarket }[]>(
        watchList.markets.map((market) => ({ id: uuidv4(), market }))
    );

    useEffect(() => {
        // should reinstate watchlist
        if (shouldReinstateWatchlist) {
            const { mode, market } = shouldReinstateWatchlist;
            if (mode === 'add') setDndItems([...dndItems, { id: uuidv4(), market }]);
            else if (mode === 'remove') setDndItems(dndItems.filter((i) => i.market.celer !== market.celer));
        }
        dispatch(setShouldReinstateWatchlist(false));
    }, [shouldReinstateWatchlist]);

    useEffect(() => {
        // always define shouldReinstateWatchlist as false onMount
        dispatch(setShouldReinstateWatchlist(false));
    }, []);

    return (
        <div ref={moduleBodyRef} className="h-full flex flex-col bg-brand-background">
            <WatchListControls
                size={size}
                modalMode={modalMode}
                showControls={showControls}
                setModalMode={setModalMode}
                setDndItems={setDndItems}
            />
            <MemoizedDndBody
                dndItems={dndItems}
                setDndItems={setDndItems}
                orderDisclosure={orderDisclosure}
                watchListId={watchList.id}
            />
        </div>
    );
};

export default TickersModuleBody;

const MemoizedDndBody = memo(({ dndItems, setDndItems, orderDisclosure, watchListId }: any) => {
    return (
        <div className="flex-1 basis-0 overflow-auto">
            {dndItems.length !== 0 && (
                <DndTickers
                    items={dndItems}
                    setItems={setDndItems}
                    handlers={orderDisclosure[1]}
                    watchListId={watchListId}
                />
            )}
            {dndItems.length === 0 && (
                <div className="flex justify-center items-center h-full text-neutral-400 px-2 text-sm text-center">
                    <span>Start adding tickers with the Instrument Selector</span>
                </div>
            )}
        </div>
    );
});

MemoizedDndBody.displayName = 'MemoizedDndBody';
