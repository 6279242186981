import { collapseBalances } from '@/helpers/balanceHelper';
import { aggregateBalances, sortBalances } from '@/helpers/celerBalanceHelper';
import { fetchLatestBalance } from '@/services/UserService';
import { getAssetName } from '@/utils/symbolMapping';
import { PayloadAction, createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { ReadyState } from 'react-use-websocket';
import { PURGE } from 'redux-persist';
import { RootState } from '../store';
import { User } from './authSlice';

export const changeBalanceAccount = createAsyncThunk(
    'balance/reset',
    async ({ credentials }: { credentials: User }, { dispatch, getState, rejectWithValue }) => {
        try {
            const { auth } = getState() as any;
            return await fetchLatestBalance(credentials, auth.currentAccount);
        } catch (err) {
            return rejectWithValue((err as ChangeBalanceAccountError).code);
        }
    }
);

export enum ChangeBalanceAccountStatus {
    SUCCESS = 1,
    BAD_REQUEST = 2,
    UNEXPECTED = -1
}

export class ChangeBalanceAccountError extends Error {
    public constructor(public code: ChangeBalanceAccountStatus, message?: string) {
        super(message);
    }
}

export interface BalanceItem {
    currency: string;
    netPosition: number;
    notionalCurrency: string;
    notionalNetPosition: number;
    settlementDate: string;
}

export interface CelerBalance {
    currency: string;
    netPosition: number;
}

export interface NewBalance extends CelerBalance {
    amount: number;
    amountInBaseCurrency: number;
    currencyName: string;
    currencyDigitalAssetName: string;
    valueDate: string;
}

export interface CollapsedBalances {
    currency: string;
    totalAmount: number;
    totalAmountInBaseCurrency: number;
    rows: [
        {
            amount: number;
            amountInBaseCurrency: number;
            valueDate: string;
        }
    ];
}

export interface NopBalances {
    balances: CollapsedBalances[];
    baseCurrency: string;
    account: string;
}

// Balances are stored as balances[currency][settlementData] = {balanceItem}
export interface BalanceState {
    status: keyof typeof ReadyState;
    celerBalance: Record<string, Record<string, BalanceItem>>;
    aggregatedBalance: BalanceItem[];
    nopBalance: NopBalances;
}

const initialState: BalanceState = {
    status: 'UNINSTANTIATED',
    celerBalance: {},
    aggregatedBalance: [],
    nopBalance: { balances: [], baseCurrency: '', account: '' }
};

export const balanceSlice = createSlice({
    name: 'balance',
    initialState,
    reducers: {
        setBalanceStatus: (state, action: PayloadAction<keyof typeof ReadyState>) => {
            state.status = action.payload;
        },
        setCelerBalance: (state, action: PayloadAction<BalanceItem[]>) => {
            const balanceItems = action.payload;

            // Update store
            for (const balanceItem of balanceItems) {
                const { currency, settlementDate } = balanceItem;
                if (!state.celerBalance[currency]) state.celerBalance[currency] = { [settlementDate]: balanceItem };
                state.celerBalance[currency][settlementDate] = balanceItem;
            }

            // Calculate aggregated balance
            state.aggregatedBalance = sortBalances(
                aggregateBalances(current(state.celerBalance)).filter((b) => b.netPosition != 0)
            );
        },
        setNopBalance: (state, action: PayloadAction<any>) => {
            const lastJsonMessage = action.payload.lastJsonMessage;
            const collapsedBalances = collapseBalances(lastJsonMessage.balances);

            state.nopBalance = {
                balances: collapsedBalances,
                baseCurrency: getAssetName(lastJsonMessage.baseCurrency),
                account: action.payload.account
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeBalanceAccount.pending, (state) => {
                state.status = 'CONNECTING';
            })
            .addCase(changeBalanceAccount.fulfilled, (state, action: PayloadAction<BalanceItem[]>) => {
                state.status = 'OPEN';
                const balanceItems = action.payload;
                state.celerBalance = {};
                for (const balanceItem of balanceItems) {
                    const { currency, settlementDate } = balanceItem;
                    if (!state.celerBalance[currency]) state.celerBalance[currency] = { [settlementDate]: balanceItem };
                    state.celerBalance[currency][settlementDate] = balanceItem;
                }

                state.aggregatedBalance = sortBalances(
                    aggregateBalances(state.celerBalance).filter((b) => b.netPosition != 0)
                );
            })
            .addCase(changeBalanceAccount.rejected, (state) => {
                state.status = 'CLOSED';
            });
        builder.addCase(PURGE, () => initialState);
    }
});

export const { setBalanceStatus, setCelerBalance, setNopBalance } = balanceSlice.actions;
export const selectBalanceStatus = (state: RootState) => state.balance.status;
export const selectCelerBalance = (state: RootState) => state.balance.celerBalance;
export const selectAggregatedBalance = (state: RootState) => state.balance.aggregatedBalance;
export const selectNopBalance = (state: RootState) => state.balance.nopBalance;

export default balanceSlice.reducer;
