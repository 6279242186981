import HighlightedPips from '@/components/common/HighlightedPips';
import { numberFormatterConfig, tickerIdleTimer } from '@/config/config';
import { PairMap } from '@/state/reducers/marketPairSlice';
import useActivePair from '@/utils/hooks/useActivePair';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { GetColorIndexesReturn, GetPipIndexesReturn, useInstrument } from '@/utils/hooks/useInstrument';
import useOrderBook from '@/utils/hooks/useOrderBook';
import usePreviousSelector from '@/utils/hooks/usePreviousSelector';
import { useTickerIdle } from '@/utils/hooks/useTickerIdle';
import { AccumulatedPriceLevel } from '@/utils/pricebook';
import BigNumber from 'bignumber.js';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useNumberFormatter } from 'react-aria';

interface PreviousBidAsks {
    bid?: number;
    ask?: number;
}

const HorizontalBook = () => {
    const activePair = useActivePair();
    const prevBidAsk = usePreviousSelector((state) => state.marketPair.bidAsk[activePair.celer]) as PreviousBidAsks;
    const [askHighlights, setAskHighlights] = useState<GetColorIndexesReturn | undefined>();
    const [bidHighlights, setBidHighlights] = useState<GetColorIndexesReturn | undefined>();

    const { formatPip, formatHighlights, formatSpread } = useInstrument(activePair.celer);

    const { asks, bids, bestAsk, bestBid } = useOrderBook(activePair.celer);

    const hasOrderBook = bids && asks && bids[0] && asks[0];

    const horizontalOrderBook = useMemo(() => {
        if (hasOrderBook) {
            const mostDepth: 'asks' | 'bids' = asks.length > bids.length ? 'asks' : 'bids';
            const asksArray = asks.reverse();

            if (mostDepth === 'asks') {
                return asksArray.map((ask, i) => {
                    const bid = bids[i];
                    return {
                        ask,
                        spread: bid && formatSpread(BigNumber(ask.price).minus(bid.price)),
                        bid
                    };
                });
            } else {
                return bids.map((bid, i) => {
                    const ask = asksArray[i];
                    return {
                        ask,
                        spread: ask && formatSpread(BigNumber(ask.price).minus(bid.price)),
                        bid
                    };
                });
            }
        }
        return [];
    }, [asks, bids]);

    const bestBidTicker = useMemo(() => formatPip(bestBid ? bestBid : 0), [bestBid]);
    const bestAskTicker = useMemo(() => formatPip(bestAsk ? bestAsk : 0), [bestAsk]);

    // for highlighting changes based on prev price
    useDidUpdate(() => {
        if (bestBid && prevBidAsk?.bid) setBidHighlights(formatHighlights(prevBidAsk.bid, bestBid, bidHighlights));
    }, [bestBid]);

    useDidUpdate(() => {
        if (bestAsk && prevBidAsk?.ask) setAskHighlights(formatHighlights(prevBidAsk.ask, bestAsk, askHighlights));
    }, [bestAsk]);

    return (
        <Fragment>
            {!hasOrderBook ? (
                <div className="p-2 italic text-sm text-gray-300">No orderbook data</div>
            ) : (
                <div className="h-full flex flex-col min-w-[450px]">
                    <div className="p-2 text-sm w-full border-b border-b-neutral-700">
                        <div className="flex flex-col justify-center items-center">
                            <div className="text-neutral-300">{activePair.show}</div>
                            <div className="flex w-full items-start text-neutral-200 text-base py-3">
                                <div className="flex-[2] text-end">
                                    <Highlights ticker={bestBidTicker} highlights={bidHighlights} />
                                </div>
                                <div className="w-16 text-center">
                                    <TopOfBookSpread
                                        bestAsk={bestAsk}
                                        bestBid={bestBid}
                                        activePair={activePair}
                                        formatSpread={formatSpread}
                                    />
                                </div>
                                <div className="flex-[2] text-start">
                                    <Highlights ticker={bestAskTicker} highlights={askHighlights} />
                                </div>
                            </div>
                        </div>

                        <div className="text-neutral-200 flex">
                            <div className="flex-1 text-end">BidSize</div>
                            <div className="flex-1 text-end">Bid</div>
                            <div className="w-16 text-center">Spread</div>
                            <div className="flex-1 text-start">Ask</div>
                            <div className="flex-1 text-start">AskSize</div>
                        </div>
                    </div>
                    <div className="order-book flex-1 basis-0 overflow-y-auto">
                        <div className="text-neutral-300 p-2 flex flex-col text-sm gap-[1px]">
                            {horizontalOrderBook.map((row, i) => {
                                return (
                                    <OrderBookRow
                                        key={i}
                                        row={row}
                                        formatPip={formatPip}
                                        totalBidQuantity={bids[bids.length - 1].accQuantity}
                                        totalAskQuantity={asks[asks.length - 1].accQuantity}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default HorizontalBook;

interface HighlightsProps {
    ticker: GetPipIndexesReturn;
    highlights?: GetColorIndexesReturn;
}

export const Highlights = ({ ticker, highlights }: HighlightsProps) => {
    // check if either bid or ask in this ticker is stale for more than 5 seconds.
    // if stale for more than 5 seconds, ticker is idle. if idle, use default colour
    const tickerIdle = useTickerIdle(tickerIdleTimer, highlights);
    return (
        <HighlightedPips
            price={ticker.price}
            boldIndexes={ticker.pipIndexes}
            colorIndexes={!tickerIdle ? highlights?.colorIndexes : []}
            color={!tickerIdle ? highlights?.color : 'text-neutral-200'}
            fontSize="text-lg"
        />
    );
};

interface OrderBookRowProps {
    row: {
        bid?: AccumulatedPriceLevel;
        spread?: string;
        ask?: AccumulatedPriceLevel;
    };
    totalAskQuantity: number;
    totalBidQuantity: number;
    formatPip: (price: number) => GetPipIndexesReturn;
}

const formatNumber = (quantity, hundreds, thousands, millions) => {
    if (quantity < 100_000) {
        return hundreds.format(quantity);
    } else if (quantity >= 100_000 && quantity < 1_000_000) {
        const value = BigNumber(quantity).minus(500).toNumber();
        if (value < 100_000) return thousands.format(quantity);
        return thousands.format(value);
    } else if (quantity >= 1_000_000) {
        const value = BigNumber(quantity).minus(50_000).toNumber();
        if (value < 1_000_000) return millions.format(quantity);
        return millions.format(value);
    }
};

const OrderBookRow = ({ row, totalBidQuantity, totalAskQuantity, formatPip }: OrderBookRowProps) => {
    const bidWidth = useMemo(
        () =>
            row.bid
                ? BigNumber.maximum(BigNumber(row.bid.accQuantity).dividedBy(totalBidQuantity).times(100).toFixed(0), 0)
                : 0,
        [row.bid]
    );
    const askWidth = useMemo(
        () =>
            row.ask
                ? BigNumber.maximum(BigNumber(row.ask.accQuantity).dividedBy(totalAskQuantity).times(100).toFixed(0), 0)
                : 0,
        [row.ask]
    );
    const defaultFormatNumber = useNumberFormatter(numberFormatterConfig);
    const thousandsFormatNumber = useNumberFormatter({ maximumFractionDigits: 0, notation: 'compact' });
    const millionsFormatNumber = useNumberFormatter({ maximumFractionDigits: 1, notation: 'compact' });

    const { price: formatBidPrice, pipIndexes: bidPipIndexes } = formatPip(row.bid ? row.bid.price : 0);
    const { price: formatAskPrice, pipIndexes: askPipIndexes } = formatPip(row.ask ? row.ask.price : 0);

    return (
        <div className="flex relative hover:bg-brand-background-dark">
            <div className="flex-[2] flex relative">
                <div className="flex-1 text-end lowercase z-10">
                    {row.bid &&
                        formatNumber(
                            row.bid.quantity,
                            defaultFormatNumber,
                            thousandsFormatNumber,
                            millionsFormatNumber
                        )}
                </div>
                <div className="flex-1 text-end z-10">
                    {row.bid && (
                        <HighlightedPips price={formatBidPrice} boldIndexes={bidPipIndexes} fontSize="text-base" />
                    )}
                </div>
                <div
                    className={`right-0 top-0 h-full absolute bg-brand-primary-dark`}
                    style={{ width: `${bidWidth}%` }}
                />
            </div>
            <div className="w-16 text-center">{row.spread}</div>
            <div className="flex-[2] flex relative">
                <div className="flex-1 text-start z-10">
                    {row.ask && (
                        <HighlightedPips price={formatAskPrice} boldIndexes={askPipIndexes} fontSize="text-base" />
                    )}
                </div>
                <div className="flex-1 text-start lowercase z-10">
                    {row.ask &&
                        formatNumber(
                            row.ask.quantity,
                            defaultFormatNumber,
                            thousandsFormatNumber,
                            millionsFormatNumber
                        )}
                </div>
                <div className={`left-0 top-0 h-full absolute bg-brand-red-dark`} style={{ width: `${askWidth}%` }} />
            </div>
        </div>
    );
};

interface TopOfBookSpreadProps {
    bestAsk: number;
    bestBid: number;
    activePair: PairMap;
    formatSpread(spread: BigNumber): string;
}

const TopOfBookSpread = ({ bestAsk, bestBid, activePair, formatSpread }: TopOfBookSpreadProps) => {
    const spread = useMemo(() => BigNumber(bestAsk).minus(bestBid), [bestAsk, bestBid]);

    const spreadRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (spreadRef.current) spreadRef.current.scrollIntoView({ inline: 'center' });
    }, [spreadRef.current, activePair]);

    return (
        <div ref={spreadRef} className="">
            {formatSpread(spread)}
        </div>
    );
};
