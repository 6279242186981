/* eslint-disable */
export const protobufPackage = 'com.celertech.marketdata.api.enums.marketdataquotetype';

export enum MarketDataQuoteType {
    INDICATIVE = 1,
    TRADEABLE = 2,
    RESTRICTIVE_TRADEABLE = 3,
    COUNTER = 4,
    INDICITIVE_AND_TRADEABLE = 5,
    UNRECOGNIZED = -1
}
